// Adsense
export const ADSENSE_CLIENT_ID = 'ca-pub-4052415671895503';
// export const ADSENSE_TAG_STORIES_PAGE_SLOT_ID = '2100637278';
// export const ADSENSE_STORY_PAGE_ASIDE_PC_1_SLOT_ID = '1026345904';
// export const ADSENSE_STORY_PAGE_ASIDE_PC_2_SLOT_ID = '7318784879';
// export const ADSENSE_STORY_PAGE_ASIDE_SP_1_SLOT_ID = '1026345904';
// export const ADSENSE_SERIES_PAGE_ASIDE_1_SLOT_ID = '6342673220';
// export const ADSENSE_SERIES_PAGE_ASIDE_2_SLOT_ID = '7318784879';

export const STUDIO_URL = process.env['NEXT_PUBLIC_TELLER_STUDIO_BASE_URL']
  ? `//${process.env['NEXT_PUBLIC_TELLER_STUDIO_BASE_URL']}`
  : undefined;
export const NOVEL_STUDIO_URL = `//${process.env['NEXT_PUBLIC_TELLER_NOVEL_STUDIO_BASE_URL']}`;

export const NEXTJS_PAGE_PATH_REGEXES = [/^\/tag\/(.+)/];

export const APP_STORE_URL = 'https://apps.apple.com/jp/app/id1238587832';

export const PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=jp.picappinc.teller&hl=ja&gl=US';

export const NameConstants = {
  SITE_NAME: 'テラーノベル',
  SITE_NAME_EN: 'Teller Novel',
  SITE_NAME_WITH_EN: 'テラーノベル（Teller Novel）',
  APP_NAME: 'テラーノベル',
  CORP_NAME: '株式会社テラーノベル',
  CORP_NAME_EN: 'Teller Novel Inc.',
  APP_HASHTAG: 'テラーノベル',
};

export const TWITTER_ID_STORAGE_KEY = 'twitterId';

export const TAG_STORIES_BY_PAGE = 70;
export const SERIES_STORIES_PER_PAGE = 70;
export const SEO_DESCRIPTION_LIMIT = 150;
