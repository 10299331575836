import type { ScriptRGBAColor } from '~/models/story-script/common';
import { formatDate } from '~/utils/form';

export const formatFromSeconds = (sec: number): string => {
  let seconds = sec;
  let result = '';
  if (seconds >= 3600) {
    const hour = Math.floor(seconds / 3600);
    result = `${hour}:`;
    seconds %= 3600;
  }

  const min = Math.floor(seconds / 60);
  result = result ? `${result}00${min}`.slice(-2) : `${result}${min}`;
  result = `${result}:`;
  seconds %= 60;

  result = `${result}00${Math.floor(seconds)}`.slice(-2);

  return result;
};

export const rgbaColorToCss = (rgbaColor: ScriptRGBAColor): string => `rgb(${
  rgbaColor.red
},
  ${rgbaColor.green},
  ${rgbaColor.blue},
  ${rgbaColor.alpha ? rgbaColor.alpha / 255 : 0})`;

export const formatResultsCount = (
  count: number,
  resultSuffix: string = '件'
): string => {
  if (!count) {
    return `-${resultSuffix}`;
  }
  if (count < 10_000) {
    return `${count.toLocaleString()}${resultSuffix}`;
  }
  if (count < 100_000) {
    const remainder = count / 10_000;
    const floored = Math.floor((remainder + Number.EPSILON) * 10) / 10;
    return `${floored}万${resultSuffix}`;
  }
  if (count < 10_000_000) {
    return `${Math.trunc(count / 10_000)}万${resultSuffix}`;
  }
  return `${Math.trunc(count / 100_000)}0万${resultSuffix}`;
};

// 1分未満 -> 1分前
// 1時間未満 -> 〇〇分前
// 24時間未満 -> 〇〇時間前
// 1週間以内 -> 〇日前
// それ以外 -> yyyy/MM/dd
export const formatNotificationDateString = (timestamp?: string): string => {
  if (!timestamp) return '';
  const date = new Date(timestamp);
  const now = new Date();
  const seconds = Math.round((now.getTime() - date.getTime()) / 1000);
  const minutes = Math.round(seconds / 60);
  const hours = Math.round(minutes / 60);
  const days = Math.round(hours / 24);
  if (seconds < 90) {
    return '1分前';
  }
  if (minutes < 60) {
    return `${minutes}分前`;
  }
  if (hours < 24) {
    return `${hours}時間前`;
  }
  if (days < 7) {
    return `${days}日前`;
  }

  return formatDate(date);
};
