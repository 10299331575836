// Email登録で利用できるドメイン一覧
export const REGISTER_EMAIL_DOMAIN_WHITE_LIST = [
  'yahoo.co.jp',
  'gmail.com',
  'ezweb.ne.jp',
  'au.com',
  'docomo.ne.jp',
  'i.softbank.jp',
  'softbank.ne.jp',
  'excite.co.jp',
  'googlemail.com',
  'hotmail.co.jp',
  'hotmail.com',
  'icloud.com',
  'live.jp',
  'me.com',
  'mineo.jp',
  'nifty.com',
  'outlook.com',
  'outlook.jp',
  'yahoo.ne.jp',
  'ybb.ne.jp',
  'ymobile.ne.jp',
];

export const isWhitelistDomain = (email: string): boolean => {
  // simplified regex https://stackoverflow.com/a/50968324
  const re = /^([^\s@]+)@((?:[\da-z-]+\.)+[a-z]{2,})$/i;
  const emailString = String(email).toLowerCase();
  const matches = emailString.match(re);
  if (matches?.length !== 3) return false;
  const domain = matches[2];
  // 登録できるドメイン一覧に含めないために、"tellernovel.com"はこちらで追加
  const whiteList = [...REGISTER_EMAIL_DOMAIN_WHITE_LIST, 'tellernovel.com'];
  return domain ? whiteList.includes(domain) : false;
};

export const validateEmail = (email: string): boolean => {
  // simplified regex https://stackoverflow.com/a/50968324
  const re = /^([^\s@]+)@((?:[\da-z-]+\.)+[a-z]{2,})$/i;
  return re.test(String(email).toLowerCase());
};

export const validatePassword = (password: string): boolean =>
  password.length >= 6;

export const validateUsername = (username: string): boolean =>
  username.trim().length > 0 && username.trim().length <= 15;

export const validateProfileDescription = (description: string): boolean =>
  description.trim().length === 0 || description.trim().length <= 200;

export const dateStrings = (date: Date | null): string[] => {
  if (!date) {
    return [];
  }
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = `${d.getFullYear()}`;

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;
  return [year, month, day];
};

export const formatDate = (date: Date | null): string => {
  const splitDate = dateStrings(date);
  if (splitDate.length === 0) {
    return '';
  }
  return splitDate.join('/');
};

export const formatDateJapanese = (date: Date | null): string => {
  const splitDate = dateStrings(date);
  if (splitDate.length === 0) {
    return '';
  }
  return `${splitDate[0]}年${splitDate[1]}月${splitDate[2]}日`;
};

export const formatCreditCardNumber = (cardNumber: string): string => {
  const trimmed = cardNumber.replace(/\s/g, '');
  return `${trimmed.slice(0, 4)} ${trimmed.slice(
    4,
    6
  )}･･ ････ ･･${trimmed.slice(-2)}`;
};

export const validatePhoneNumber = (phone: string): boolean => {
  const re = /^\d+$/;
  return phone.trim().length === 11 && re.test(phone);
};
